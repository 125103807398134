import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ContentExteriors from "./ContentExteriors";
import "./index.scss";
import ContentInteriors from "./ContentInteriors";
import ContentFilms from "./ContentFilms";
import ContentAmenities from "./ContentAmenities";
import ContentFloorplans from "./ContentFloorplans";
import Header from "./components/Header";
import ContentInfos from "./ContentInfos";

export const ContentSectionOptions = [
  { label: "Films", value: "Films" },
  { label: "Floorplans", value: "Floorplans" },
  { label: "Exteriors", value: "Exteriors" },
  { label: "Interiors", value: "Interiors" },
  { label: "Amenities", value: "Amenities" },
  { label: "Info Gallery", value: "Infos" },
];

export const ContentSection = {
  Exteriors: "Exteriors",
  Interiors: "Interiors",
  Amenities: "Amenities",
  Films: "Films",
  Floorplans: "Floorplans",
  Infos: "Info Gallery"
};

const CMSContent = () => {
  return (
    <div className="page-container">
      <div className="page-body content-page-body">
        <Header />
        <Tabs
          defaultActiveKey="content-exteriors"
          className="custom-tab"
          unmountOnExit={true}
          mountOnEnter={true}
        >
          <Tab eventKey="content-amenities" title="AMENITIES">
            <ContentAmenities />
          </Tab>
          <Tab eventKey="content-exteriors" title="EXTERIORS">
            <ContentExteriors />
          </Tab>
          <Tab eventKey="content-film" title="FLOORPLANS">
            <ContentFloorplans />
          </Tab>
          <Tab eventKey="content-films" title="FILMS">
            <ContentFilms />
          </Tab>
          <Tab eventKey="content-info" title="INFO GALLERY">
            <ContentInfos />
          </Tab>
          <Tab eventKey="content-interiors" title="INTERIORS">
            <ContentInteriors />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default CMSContent;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import closeIcon from "../../../../assets/images/close-white.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import mediaAPI from "../../../../apis/api/media";
import galleryApi from "../../../../apis/api/gallery";
import { getUploadedServerMediaUrl } from "../../../../helper/media";
import { ContentSectionOptions, ContentSection } from "..";
import { MEDIA_TYPE_ENUM } from "../../../../constants/master-data";
import { useDispatch, useSelector } from "react-redux";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import { GALLERY_TYPE } from "../../../../constants/options";

const validationSchema = yup.object().shape({
    contentTitle: yup
        .string()
        .trim()
        .required("Content title is a required field"),
    contentSection: yup
        .string()
        .trim()
        .required("Content section is a required field"),
});

const floorPlanValidationSchema = yup.object().shape({
    contentTitle: yup
        .string()
        .trim()
        .required("Content title is a required field"),
    contentSection: yup
        .string()
        .trim()
        .required("Content section is a required field"),
    unitId: yup
        .string()
        .trim()
        .required("Associated Residence is a required field"),
});

const EditContentModal = ({
        content,
        thumbnailContent,
        gallery,
        show,
        setShow,
        onSaveSuccess,
        contentType,
        mediaType,
        galleries,
        orderProps,
        videoSrc,
        uniqueOrder
    }) => {
    const dispatch = useDispatch();
    const units = useSelector((state) => state.cms.units);

    const [file, setFile] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [order, setOrder] = useState(orderProps);
    const [contentTitle, setContentTitle] = useState(content?.name || "");
    const [contentSection, setContentSection] = useState(
        ContentSectionOptions.find((v) => v.label === contentType)?.label
    );
    const [unitId, setUnitId] = useState(gallery?.unit?.id);
    const [isShowDeleteContentModal, setIsShowDeleteContentModal] =
        useState(false);
    const [isShowReplaceContentModal, setIsShowReplaceContentModal] =
        useState(false);
    const [errors, setErrors] = useState({});
    const [warning, setWarning] = useState({});
    const [enableBtnSubmit, setEnableBtnSubmit] = useState(true);
    const fileRef = useRef();
    const thumbnailRef = useRef();
    const [replaceContentType, setReplaceContentType] = useState("");

    const ordersOptions = useMemo(() => {
        return uniqueOrder?.map(order => ({
            label: order,
            value: order
        }));
    }, [uniqueOrder]);

    useEffect(() => {
        if (content) {
            setOrder(content?.order);
        }
    }, [content]);

    useEffect(() => {
        if (file) {
            setContentTitle(file.name);
        }
    }, [file]);

    useEffect(() => {
        dispatch(reqGetListUnits());
    }, []);

    useEffect(() => {
        if (!unitId || !Array.isArray(galleries)) {
            return;
        }

        if (galleries.find((_gallery) => _gallery.unit.id === unitId && _gallery.id !== gallery.id)) {
            setWarning({ unitId: "Associated Residence already had a floorplan" });
            return;
        }

        setWarning({ unitId: undefined });
    }, [unitId]);

    const handleUpdateContent = () => {
        // if (!enableBtnSubmit) {
        //     toast.info("Please wait, media file uploading!");
        //     return;
        // }
        switch (contentSection) {
            case ContentSection.Floorplans:
                handleUpdateContentFloorplans();
                return;
            case ContentSection.Exteriors:
                handleUpdateContentExteriors();
                return;
            case ContentSection.Interiors:
                handleUpdateContentInteriors();
                return;
            case ContentSection.Amenities:
                handleUpdateContentAmenities();
            case ContentSection.Infos:
                handleUpdateContentInfos();
            case ContentSection.Films:
                handleUpdateContentFilm();
                return;
            default:
                return;
        }
    };

    const handleUpdateContentFilm = async () => {
        if (!content || !thumbnailContent) return;
    
        try {
          const data = {
            order,
            contentTitle,
            contentSection,
            thumbnail,
          };
          setErrors({});
          const result = await validationSchema.validate(data, {
            abortEarly: false,
          });
    
          setEnableBtnSubmit(false);
          const formData = new FormData();
          formData.append("type", "video");
          formData.append("order", result?.order);
          formData.append("name", result?.contentTitle);
          formData.append("path", "media/media_videos/films");
          if (file) formData.append("file", file);
    
          toast.info("Please wait, media file uploading!");
          const formDataThumbnail = new FormData();
          formDataThumbnail.append("type", "image");
          formDataThumbnail.append("name", result?.contentTitle);
          formDataThumbnail.append("path", "media/media_videos/thumbnails");
          if (thumbnail) formDataThumbnail.append("file", thumbnail);
    
          const updatedContent = await mediaAPI.updateMedia(content.id, formData);
          const updatedThumbnail = await mediaAPI.updateMedia(thumbnailContent.id, formDataThumbnail);
          if (updatedContent?.data && updatedThumbnail?.data) {
            toast.success("Content updated successfully!");
            handleClose && handleClose();
            onSaveSuccess && onSaveSuccess();
          }
        } catch (err) {
          if (err.inner) {
            const errs = {};
            err.inner.forEach((err) => {
              errs[err.path] = err.message;
            });
            setErrors(errs);
          }
          setEnableBtnSubmit(true);
          toast.error("Something went wrong");
        }
      };

    const handleUpdateContentAmenities = async () => {
        try {
            const data = {
                order,
                contentTitle,
                contentSection,
                unitId,
            };
            setErrors({});
            const result = await validationSchema.validate(data, {
                abortEarly: false,
            });
            const formData = new FormData();
            formData.append("type", "image");
            formData.append("order", result?.order);
            formData.append("name", result?.contentTitle);
            formData.append("path", "media/media_images/image_gallery");

            if (file) {
                formData.append("file", file);
            }
            toast.info("Please wait, media file uploading!");
            const updated = await mediaAPI.updateMedia(content.id, formData);
            const { id, createdAt, updatedAt, media, ...rest } = gallery;
            const updatedGallery = await galleryApi.updateGallery(gallery.id, {
                ...rest,
                media: [updated?.data?.id],
                order: result?.order,
                type: GALLERY_TYPE.MEDIA_IMAGES,
                unitId: result?.unitId,
                category: contentSection.toLowerCase(),
            });
            if (updated?.data && updatedGallery?.data) {
                toast.success("Content updated successfully!");
                handleClose && handleClose();
                onSaveSuccess && onSaveSuccess();
            }
        } catch (err) {
            if (err.inner) {
                const errs = {};
                err.inner.forEach((err) => {
                    errs[err.path] = err.message;
                });
                setErrors(errs);
            }
            toast.error("Something went wrong");
        }
    };

    const handleUpdateContentInfos = async () => {
        try {
            const data = {
                order,
                contentTitle,
                contentSection,
                unitId,
            };
            setErrors({});
            const result = await validationSchema.validate(data, {
                abortEarly: false,
            });
            const formData = new FormData();
            formData.append("type", "image");
            formData.append("order", result?.order);
            formData.append("name", result?.contentTitle);
            formData.append("path", "media/media_images/image_gallery");

            if (file) {
                formData.append("file", file);
            }
            toast.info("Please wait, media file uploading!");
            const updated = await mediaAPI.updateMedia(content.id, formData);
            const { id, createdAt, updatedAt, media, ...rest } = gallery;
            const updatedGallery = await galleryApi.updateGallery(gallery.id, {
                ...rest,
                media: [updated?.data?.id],
                order: result?.order,
                type: GALLERY_TYPE.MEDIA_IMAGES,
                unitId: result?.unitId,
                category: contentSection !== 'Info Gallery' ? contentSection.toLowerCase() : 'infos',
            });
            if (updated?.data && updatedGallery?.data) {
                toast.success("Content updated successfully!");
                handleClose && handleClose();
                onSaveSuccess && onSaveSuccess();
            }
        } catch (err) {
            if (err.inner) {
                const errs = {};
                err.inner.forEach((err) => {
                    errs[err.path] = err.message;
                });
                setErrors(errs);
            }
            toast.error("Something went wrong");
        }
    };

    const handleUpdateContentFloorplans = async () => {
        if (!content) return;

        try {
            const data = {
                contentTitle,
                contentSection,
                unitId,
            };
            setErrors({});
            const result = await floorPlanValidationSchema.validate(data, {
                abortEarly: false,
            });

            setEnableBtnSubmit(false);
            const formData = new FormData();
            formData.append("type", "image");
            formData.append("name", result?.contentTitle);
            formData.append("path", "media/floorplans");

            if (file) {
                formData.append("file", file);
            }

            toast.info("Please wait, media file uploading!");
            const updated = await mediaAPI.updateMedia(content.id, formData);

            if (updated?.data) {
                const { id, createdAt, updatedAt, media, ...rest } = gallery;

                await galleryApi.updateGallery(gallery.id, {
                    ...rest,
                    type: GALLERY_TYPE.FLOOR_PLANS,
                    media: [updated?.data?.id],
                    unitId: result?.unitId,
                    category: contentSection.toLowerCase(),
                });
                toast.success("Content updated successfully!");
                handleClose && handleClose();
                onSaveSuccess && onSaveSuccess();
            }
        } catch (err) {
            if (err.inner) {
                const errs = {};
                err.inner.forEach((err) => {
                    errs[err.path] = err.message;
                });
                setErrors(errs);
            }
            setEnableBtnSubmit(true);
            toast.error("Something went wrong");
        }
    };

    const handleUpdateContentInteriors = async () => {
        if (!content) return;

        try {
            const data = {
                order,
                contentTitle,
                contentSection,
            };
            setErrors({});
            const result = await validationSchema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            formData.append("type", "image");
            formData.append("order", result?.order);
            formData.append("name", result?.contentTitle);
            formData.append("path", "media/media_images/image_gallery");

            if (file) {
                formData.append("file", file);
            }

            toast.info("Please wait, media file uploading!");
            const updated = await mediaAPI.updateMedia(content.id, formData);
            if (updated?.data) {
                const { id, createdAt, updatedAt, media, ...rest } = gallery;

                await galleryApi.updateGallery(id, {
                    ...rest,
                    type: GALLERY_TYPE.MEDIA_IMAGES,
                    media: [updated?.data?.id],
                    order: result?.order,
                    unitId: result?.unitId,
                    category: contentSection.toLowerCase(),
                });

                toast.success("Content updated successfully!");
                handleClose && handleClose();
                onSaveSuccess && onSaveSuccess();
            }
        } catch (err) {
            if (err.inner) {
                const errs = {};
                err.inner.forEach((err) => {
                    errs[err.path] = err.message;
                });
                setErrors(errs);
            }
            toast.error("Something went wrong");
        }
    };

    const handleUpdateContentExteriors = async () => {
        if (!content) return;

        try {
            const data = {
                order,
                contentTitle,
                contentSection,
                unitId,
            };
            setErrors({});
            const result = await validationSchema.validate(data, {
                abortEarly: false,
            });

            const formData = new FormData();
            formData.append("type", "image");
            formData.append("order", result?.order);
            formData.append("name", result?.contentTitle);
            formData.append("path", "media/media_images/image_gallery");

            if (file) {
                formData.append("file", file);
            }
            toast.info("Please wait, media file uploading!");
            const updated = await mediaAPI.updateMedia(content.id, formData);
            const { id, createdAt, updatedAt, media, ...rest } = gallery;
            const updatedGallery = await galleryApi.updateGallery(gallery.id, {
                ...rest,
                type: GALLERY_TYPE.MEDIA_IMAGES,
                media: [updated?.data?.id],
                order: result?.order,
                unitId: result?.unitId,
                category: contentSection.toLowerCase(),
            });
            if (updated?.data && updatedGallery?.data) {
                toast.success("Content updated successfully!");
                handleClose && handleClose();
                onSaveSuccess && onSaveSuccess();
            }
        } catch (err) {
            if (err.inner) {
                const errs = {};
                err.inner.forEach((err) => {
                    errs[err.path] = err.message;
                });
                setErrors(errs);
            }
            toast.error("Something went wrong");
        }
    };

    const handleDeleteContent = async () => {
        if (!content) return;

        try {
            const deleted = await mediaAPI.deleteMedia(content.id);
            if (deleted.data) {
                await galleryApi.deleteGallery(gallery?.id);

                toast.success("Content deleted successfully!");
                handleOpenDeleteContentModal();
                handleClose && handleClose();
                onSaveSuccess && onSaveSuccess();
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    const handleOpenDeleteContentModal = () => {
        setIsShowDeleteContentModal(true);
    };

    const handleOpenReplaceContentModal = (type) => () => {
        setIsShowReplaceContentModal(true);
        setReplaceContentType(type);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleCloseDeleteContentModal = () => {
        setIsShowDeleteContentModal(false);
    };

    const handleCloseReplaceContentModal = () => {
        setIsShowReplaceContentModal(false);
    };

    const onOpenFileInput = () => {
        fileRef.current.click();
    };

    const onOpenThumbnailInput = () => {
        thumbnailRef.current.click();
    };

    const handleBrowserFile = (e) => {
        const isImage = e.target.files[0].type.startsWith("image");
        // if (!isImage) return;
        setFile(e.target.files[0]);
        handleCloseReplaceContentModal();
    };

    const handleBrowseThumbnail = (e) => {
        setThumbnail(e.target.files[0]);
        handleCloseReplaceContentModal();
    };

    const renderImagePreview = () => {
        if (file) {
            return URL.createObjectURL(file);
        }

        if (videoSrc) {
            return getUploadedServerMediaUrl(videoSrc);
        }

        if (content && content.path) {
            return getUploadedServerMediaUrl(content.path);
        }

        return "/images/image.png";
    };

    const renderThumbnailPreview = () => {
        if (thumbnail) {
            return URL.createObjectURL(thumbnail);
        }

        if (thumbnailContent && thumbnailContent.path) {
            return getUploadedServerMediaUrl(thumbnailContent.path);
        }

        return "/images/image.png";
    };

    if (isShowReplaceContentModal) {
        return (
            <Modal
                className="wrap-replace-content-modal"
                show={isShowReplaceContentModal}
                onHide={handleCloseReplaceContentModal}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>REPLACE CONTENT</Modal.Title>
                </Modal.Header>
                <Modal.Body className="wrap-modal-body">
                    <div className="close-btn">
                        <img
                            src={closeIcon}
                            alt="close-icon"
                            onClick={handleCloseReplaceContentModal}
                        />
                    </div>
                    <div className="title">CONTENT</div>
                    <div className="browser-file mt-0" onClick={onOpenFileInput}>
                        BROWSE FILES
                        <input
                            ref={fileRef}
                            accept={`${replaceContentType === "video" ? "video" : "image"}/*`}
                            id="file"
                            className="hidden"
                            type="file"
                            onChange={(e) => (replaceContentType === "thumbnail" ? handleBrowseThumbnail(e) : handleBrowserFile(e))}
                        />
                    </div>
                    {mediaType !== MEDIA_TYPE_ENUM.VIDEO && (
                        <div className="import" onClick={() => {}}>
                            <span className="import-title">Or import from a URL</span>
                            <div className="import-form">
                                <input type="text" placeholder="Add a URL" />
                                <span className="import-button">IMPORT</span>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        );
    }

    if (isShowDeleteContentModal) {
        return (
            <Modal
                className="wrap-delete-content-modal"
                show={isShowDeleteContentModal}
                onHide={handleCloseDeleteContentModal}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Content?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="wrap-modal-body">
                    <div className="close-btn">
                        <img
                            src={closeIcon}
                            alt="close-icon"
                            onClick={handleCloseDeleteContentModal}
                        />
                    </div>
                    <div className="confirm">
                        Are you sure you want to delete {content?.name}?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={handleDeleteContent}>YES, DELETE</div>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal
            className="wrap-update-content-modal"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Update Content</Modal.Title>
            </Modal.Header>
            <Modal.Body className="wrap-modal-body">
                <div className="close-btn">
                    <img src={closeIcon} alt="close-icon" onClick={handleClose} />
                </div>
                <div className="content">
                    <div className="title">CONTENT</div>
                    <div div className="upload-input cursor-pointer">
                        {mediaType === MEDIA_TYPE_ENUM.IMAGE && (
                            <img
                                style={{ width: "100%" }}
                                className="img-fluid"
                                src={renderImagePreview()}
                                alt=""
                            />
                        )}
                        {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
                            <video className="img-fluid">
                                <source src={renderImagePreview()} type="video/mp4" />
                            </video>
                        )}
                        <div
                            onClick={handleOpenReplaceContentModal(mediaType)}
                            className={`upload-btn ${mediaType}`}
                        >
                            <img className="upload-icon" src={uploadIcon} alt="upload-icon" />
                        </div>
                    </div>
                    <span className="error">{errors?.file}</span>
                </div>
                {mediaType === MEDIA_TYPE_ENUM.VIDEO && (
                    <div className="content thumbnail">
                        <div className="title">THUMBNAIL</div>
                        <div className="upload-input">
                            <img
                                style={{ width: "100%" }}
                                className="img-fluid cursor-pointer"
                                src={renderThumbnailPreview()}
                                alt=""
                            />
                            <div
                                onClick={handleOpenReplaceContentModal("thumbnail")}
                                className={`upload-btn thumbnail ${
                                    content || file ? "has-file" : ""
                                }`}
                            >
                                <img
                                    className="upload-icon"
                                    src={uploadIcon}
                                    alt="upload-icon"
                                />
                            </div>
                        </div>
                        <span className="error">{errors?.thumbnail}</span>
                    </div>
                )}
                <div className="info">
                    <div className="title">INFORMATION</div>
                    <div className="wrap-form-group">
                        <Form.Group>
                            <Form.Label>Content Title*</Form.Label>
                            <FormControl
                                id="contentTitle"
                                name="contentTitle"
                                type="input"
                                value={contentTitle}
                                placeholder="Content Title"
                                onChange={(e) => setContentTitle(e.target.value)}
                            />
                            <span className="error">{errors?.contentTitle}</span>
                        </Form.Group>
                    </div>
                    <div className="wrap-form-group">
                        <Form.Group>
                            <Form.Label>Content Section*</Form.Label>
                            <Select
                                className="content-section"
                                classNamePrefix="select"
                                value={{ value: contentSection, label: contentSection }}
                                options={ContentSectionOptions}
                                name="contentSection"
                                isSearchable={false}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                                isDisabled={true}
                                onChange={(item) => setContentSection(item?.value)}
                            />
                            <span className="error">{errors?.contentSection}</span>
                        </Form.Group>
                    </div>
                    {contentSection !== ContentSection.Floorplans && (
                        <div className="wrap-form-group">
                            <Form.Group>
                                <Form.Label>Content Order</Form.Label>
                                <Select
                                    className="content-section"
                                    classNamePrefix="select"
                                    id="order"
                                    name="order"
                                    isSearchable={false}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                    value={ordersOptions?.find(option => option.value === order)}
                                    options={ordersOptions}
                                    onChange={(e) => setOrder(e.value)}
                                />
                                <span className="error">{errors?.order}</span>
                            </Form.Group>
                        </div>
                    )}
                    {contentSection === ContentSection.Floorplans && (
                        <div className="wrap-form-group">
                            <Form.Group>
                                <Form.Label>associated residence</Form.Label>
                                <Select
                                    className="content-section"
                                    classNamePrefix="select"
                                    value={{
                                        value: unitId || "",
                                        label: `Residence ${
                                            units.find((unit) => unit.id === unitId)?.name || ""
                                        }`,
                                    }}
                                    options={units.map((unit) => ({
                                        label: `Residence ${unit.name}`,
                                        value: unit.id,
                                    }))}
                                    name="unitId"
                                    isSearchable={false}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    }}
                                    onChange={(item) => setUnitId(item?.value)}
                                    isDisabled
                                />
                                <span className="error">{errors?.unitId}</span>
                                <span className="warning">{warning?.unitId}</span>
                            </Form.Group>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
        <span className="save" onClick={handleUpdateContent}>
            SAVE
        </span>
        {contentSection !== ContentSection.Floorplans &&
            <span className="delete" onClick={handleOpenDeleteContentModal}>
                DELETE CONTENT
            </span>
        }
            </Modal.Footer>
        </Modal>
    );
};

export default EditContentModal;

import React, { useEffect, useState } from 'react';
import './index.scss';

import { getMediaUrl, getUploadedServerMediaUrl } from '../../../helper/media';
import PopupGallery from '../../media/popup-gallery';
import galleryApi from '../../../apis/api/gallery';
import { ACTION_NAME, GALLERY_TYPE, WEBSOCKET_CHANNEL } from '../../../constants/options';
import socket from '../../../helper/socket';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Interiors = ({ isPresentation }) => {
  const [data, setData] = useState([]);
  const mediaData =
    data
      ?.map((item) => (item?.media?.length > 0 ? item?.media[0] : null))
      .filter((item) => item.isActive) || [];
  mediaData.sort((a, b) => (a?.order < b?.order ? -1 : 1));

  const [showGallery, setShowGallery] = useState(null);

  const loadData = async () => {
    try {
      const data = {
        'type[equal]': GALLERY_TYPE.MEDIA_IMAGES,
        "category[equal]": 'interiors'
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setData(res?.data);
      }
    } catch (e) {
      console.log('error get floor');
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_GALLERY) {
      handleShowGallery(content.data.idx);
    }
    if (content.action === ACTION_NAME.CLOSE_GALLERY) {
      handleCloseGallery();
    }
  };
  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY, {
        idx,
      });
    }
    setShowGallery(idx);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY);
    }
    setShowGallery(false);
  };

  return (
    <div className='interiors media-images-content'>
      <div className='media-images-content-wrapper'>
        {mediaData?.map((item, index) => {
          const position = index % 3;
          switch (position) {
            case 1:
              return null;
            case 0:
              return (
                <div className={'media-images-content-wrapper-row'} key={index}>
                  <div
                    key={item.id}
                    onClick={() => handleShowGallery(item?.path)}
                    className={`media-item-${position}`}
                  >
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      alt={item?.name}
                      src={getUploadedServerMediaUrl(item?.path)}
                    />
                  </div>
                  {mediaData[index + 1] && (
                    <div
                      key={mediaData[index + 1].id}
                      onClick={() => handleShowGallery(mediaData[index + 1]?.path)}
                      className={`media-item-${position + 1}`}
                    >
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        alt={mediaData[index + 1]?.name}
                        src={getUploadedServerMediaUrl(mediaData[index + 1]?.path)}
                      />
                    </div>
                  )}
                </div>
              );
            case 2:
              return (
                <div className={'media-images-content-wrapper-row'} key={index}>
                  <div
                    key={item.id}
                    onClick={() => handleShowGallery(item?.path)}
                    className={`media-item-${position}`}
                  >
                    <LazyLoadImage
                      className='lazy-load-image'
                      alt={item?.name}
                      src={getUploadedServerMediaUrl(item?.path?.replace(".", "-thumb."))}
                      onError={(e) => {
                        e.currentTarget.src = getUploadedServerMediaUrl(item?.path)
                      }}
                    />
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
      <PopupGallery
        isPresentation={isPresentation}
        show={showGallery}
        listImage={mediaData}
        onClose={handleCloseGallery}
        isShowPreNextSlide={mediaData.length > 1}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import './index.scss';

import { getMediaUrl, getUploadedServerMediaUrl } from '../../../helper/media';
import PopupGallery from '../../media/popup-gallery';
import galleryApi from '../../../apis/api/gallery';
import { ACTION_NAME, GALLERY_TYPE, WEBSOCKET_CHANNEL } from '../../../constants/options';
import socket from '../../../helper/socket';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function splitToChunksOfLengthN(array, chunkSize) {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    results.push(chunk);
  }
  return results;
}

export const Amenities = ({ isPresentation }) => {
  const [data, setData] = useState([]);
  const mediaData = data?.map((item) => (item?.media?.length > 0 ? item?.media[0] : null)).filter((item) => item.isActive) || [];
  mediaData.sort((a, b) => (a?.order < b?.order ? -1 : 1));
  const mediaLayout = splitToChunksOfLengthN(mediaData, 2);

  const [showGallery, setShowGallery] = useState(null);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
        "category[equal]": "amenities"
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setData(res?.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_GALLERY) {
      handleShowGallery(content.data.idx);
    }
    if (content.action === ACTION_NAME.CLOSE_GALLERY) {
      handleCloseGallery();
    }
  };
  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY, {
        idx,
      });
    }
    setShowGallery(idx);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY);
    }
    setShowGallery(false);
  };

  return (
    <div className='amenities media-images-content'>
      <div className='media-images-content-wrapper'>
        {mediaLayout?.map((mediaRow, index) => (
          <div className={'media-images-content-wrapper-row'} key={index}>
            {mediaRow?.map((item, mediaIndex) => {
              return (
                <div
                  key={item.id}
                  onClick={() => handleShowGallery(item?.path)}
                  className={`media-item-${index%2}-${mediaIndex%4}`}
                >
                  <LazyLoadImage
                    className='lazy-load-image'
                    alt={item?.name}
                    src={getUploadedServerMediaUrl(item?.path?.replace(".", "-thumb."))}
                    onError={(e) => {
                      e.currentTarget.src = getUploadedServerMediaUrl(item?.path)
                    }}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <PopupGallery
        isPresentation={isPresentation}
        show={showGallery}
        listImage={mediaData}
        onClose={handleCloseGallery}
        isShowPreNextSlide={mediaData.length > 1}
      />
    </div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import FloorPlans from './components/floor-plans/floor-plans';
import galleryApi from '../../apis/api/gallery';
import { useDispatch } from 'react-redux';
import { reqGetGalleryList } from '../../reduxs/gallery/action';
import { FLOOR_TYPE, LEVEL_TYPE } from '../../constants/master-data';
import socket from '../../helper/socket';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import { Amenities } from './components/amenities';
import { Exteriors } from './components/exteriors';
import { Infos } from './components/info';
import Films from './components/films';
import { Interiors } from './components/interiors';

const getSubTabs = () => ({
  amenities: {
    id: 'amenities',
    name: 'AMENITIES',
  },
  exteriors: {
    id: 'exteriors',
    name: 'EXTERIORS',
  },
  floorplans: {
    id: 'floorplans',
    name: 'FLOORPLANS',
  },
  films: {
    id: 'films',
    name: 'FILMS',
  },
  info: {
    id: 'info',
    name: 'Info Gallery',
  },
  interiors: {
    id: 'interiors',
    name: 'INTERIORS',
  },
});
// const RENDER_ID_ORLA = "6434c906df01b100465efae8";
// const FILM_ID_ORLA = "64619a73e03ea7d3fc35b3c2";
const GalleryLanding = ({ isPresentation }) => {
  const SUB_TABS = getSubTabs();
  const [subTabActive, setSubTabActive] = useState(SUB_TABS.amenities.id);
  const [floor, setFloor] = useState([]);
  const [floorDetail, setFloorDetail] = useState({});
  // const [imageRender, setImgRender] = useState([]);

  const dispatch = useDispatch();

  const pageRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB) {
      return onClickSubTab(content.data.name);
    }
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    dispatch(reqGetGalleryList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(async () => {
    if (subTabActive === SUB_TABS.floorplans.id) {
      await handleGetListLevel(LEVEL_TYPE.BHT_LEVEL);
      await handleGetGalleryDetail(FLOOR_TYPE.BAKU_FLOOR);
    }
  }, [subTabActive]);

  const handleGetListLevel = async (type) => {
    try {
      const data = {
        'type[equal]': type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloor(res.data);
      }
    } catch (e) {
      console.log('error get floor');
    }
  };

  // const handleGetImageRender = async (galleryId) => {
  //   const res = await galleryApi.getGalleryDetail(galleryId);
  //   if (res) {
  //     if (subTabActive === SUB_TABS.films.id) {
  //       setFilmRender(res.data);
  //     } else {
  //       // setImgRender(res.data);
  //     }
  //   }
  // };

  // const handleGetListFloor = async (type) => {
  //   try {
  //     const data = {
  //       "type[equal]": type,
  //     };
  //     const res = await galleryApi.getListGalleryFloor(data);
  //     if (res) {
  //       setFloor(res.data);
  //     }
  //   } catch (e) {
  //     console.log("error get floor");
  //   }
  // };

  const handleGetGalleryDetail = async (type) => {
    try {
      const data = {
        'type[equal]': type,
      };
      const floorDetailRes = await galleryApi.findOneGalleryDetail(data);
      if (floorDetailRes) {
        setFloorDetail(floorDetailRes.data);
      }
    } catch (e) {
      console.log('error get floor');
    }
  };

  const onClickSubTab = (name) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB, {
        name,
      });
    }
    setSubTabActive(name);
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const renderTab = () => {
    return (
      <div className='gallery-nav'>
        {Object.values(SUB_TABS).map((item, idx) => (
          <div
            onClick={() => onClickSubTab(item.id)}
            key={`subTab-${idx}`}
            className={`sticky-nav-link ${subTabActive === item.id ? 'active' : ''}`}
          >
            <div className='sticky-nav-text'>{item.name}</div>
          </div>
        ))}
      </div>
    );
  };
  const renderContent = () => {
    switch (subTabActive) {
      case SUB_TABS.amenities.id:
        return <Amenities isPresentation={isPresentation} />;
      case SUB_TABS.floorplans.id:
        return <FloorPlans isPresentation={isPresentation} data={floor} />;
      case SUB_TABS.exteriors.id:
        return <Exteriors isPresentation={isPresentation}/>;
      case SUB_TABS.interiors.id:
        return <Interiors isPresentation={isPresentation} />;
      case SUB_TABS.info.id:
        return <Infos isPresentation={isPresentation}/>;
      case SUB_TABS.films.id:
        return <Films isPresentation={isPresentation}/>;
    }
  };
  return (
    <div className={`static-page-wf gallery-landing ${isPresentation && 'presentation'}`}>
      {renderTab()}
      <div className={`gallery-content ${isPresentation && 'gallery-content-presentation'}`} ref={pageRef} onScroll={onScroll}>
        {renderContent()}
      </div>
      {/* {!isPresentation && <div className='bottom-top' />} */}
    </div>
  );
};
export default GalleryLanding;

import React, { useMemo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GALLERY_TYPE } from "../../../constants/options";
import galleryApi from "../../../apis/api/gallery";
import AddContentModal from "./components/AddContentModal";
import EditContentModal from "./components/EditContentModal";
import { getUploadedServerMediaUrl } from "../../../helper/media";
import mediaAPI from "../../../apis/api/media";
import { ContentSection } from ".";
import { MEDIA_TYPE_ENUM } from "../../../constants/master-data";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContentInfos = () => {
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [galleries, setGalleries] = useState([]);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [seletedGallery, setSeletedGallery] = useState(null);

  const uniqueOrder = useMemo(() => {
    return (galleries || [] ).map(it => (it.media?.[0]?.order ?? it.media?.[1]?.order ?? '')).filter(it => it).sort((a, b) => a - b);
  }, [galleries]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
        "category[equal]": "infos",
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res && res.data) {
        let result = res.data;
        if (search) {
          result = result.filter(
            (gallery) =>
              gallery.media &&
              gallery.media[0]?.name?.toLowerCase().includes(search)
          );
        }
        setGalleries(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onCreateSuccess = () => {
    loadData();
  };

  const onSaveSuccess = () => {
    loadData();
  };

  const onEditContent = (gallery) => {
    setSeletedGallery(gallery);
    setIsShowEditContentModal(true);
  };

  const handleUpdateActive = async (e, selectedItem) => {
    if (!selectedItem) return;

    try {
      const formData = new FormData();
      formData.append("type", "image");
      formData.append("isActive", e.target.checked);
      const updated = await mediaAPI.updateMedia(selectedItem.id, formData);
      if (updated?.data) {
        toast.success("Content updated successfully!");
      }
    } catch (err) {
      toast.error("Content updated failed!");
    }
  };

  const renderListMedia = () => {
    if (!galleries?.length && !loading) {
      return <div className="d-flex justify-content-center">NO IMAGE</div>;
    }
    return galleries.sort((a, b) => (a.media?.[0]?.order ?? a.media?.[1]?.order ?? 999) - (b.media?.[0]?.order ?? b.media?.[1]?.order ?? 999))?.map((g, index) => {
      const item = g?.media?.[0];
      return (
        <div key={index} className="wrap-media-item">
          <LazyLoadImage
            className='content-image'
            alt={item?.name}
            src={getUploadedServerMediaUrl(item?.path?.replace(".", "-thumb."))}
            onError={(e) => {
              e.currentTarget.src = getUploadedServerMediaUrl(item?.path)
            }}
          />
          <div className="content-title-row">
            <span className="content-title">
              {item?.name || "Content title"}
            </span>
            <div className="content-action">
              <div className="status">
                <div className="form-check form-switch my-n1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="content-state-1"
                    defaultChecked={item?.isActive}
                    onChange={(e) => handleUpdateActive(e, item)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="content-state-1"
                  />
                </div>
              </div>
              <div onClick={() => onEditContent(g)}>
                <div className="action-edit">
                  <img
                    className="img-fluid"
                    src="/icons/edit-button.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="wrap-content">
      <div className="wrap-media-list">{renderListMedia()}</div>
      <div
        className="add-new-content"
        onClick={() => setIsShowAddContentModal(true)}
      >
        <img className="img-fluid" src="/icons/plus-black.svg" alt="" />
        <span>ADD NEW CONTENT</span>
      </div>
      {isShowAddContentModal && (
        <AddContentModal
          uniqueOrder={uniqueOrder}
          contentType={ContentSection.Infos}
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
        />
      )}
      {isShowEditContentModal && (
        <EditContentModal
          uniqueOrder={uniqueOrder}
          mediaType={MEDIA_TYPE_ENUM.IMAGE}
          show={isShowEditContentModal}
          setShow={setIsShowEditContentModal}
          onSaveSuccess={onSaveSuccess}
          content={seletedGallery?.media?.[0]}
          gallery={seletedGallery}
          contentType={ContentSection.Infos}
        />
      )}
    </div>
  );
};

export default ContentInfos;
